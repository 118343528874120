import {bindActionCreators} from 'redux';

import axios from 'axios';
import config from '../config';
import { createAuth } from '../Helpers';

import Timer from '../Timer';

export const reducerName = 'infoSlideState';

const INFOSLIDE_GETALL_RESPONSE = 'INFOSLIDE_GETALL_RESPONSE';
const SET_CURRENT_SLIDE_INDEX = 'SET_CURRENT_SLIDE_INDEX';

const initialState = {
    infoSlides: [],
    currentSlideIndex: 0,
};

var timer = null;

export const actionCreators = {
     setTimer : (interval,timeoutfunction) => (dispacth,getState)=> {
        if(timer)
        {
            timer.pause();
        }
        timer = new Timer(timeoutfunction,interval);
    },  
    clearTimer : ()=> async(dispacth,getState) => {
        if(timer)
        {
            timer.pause();
            timer=null;
        }
    },
    getAll: (deviceId) => async (dispatch, getState) => {

        if (deviceId) {

            axios({
                method: 'GET',
                url: `${config.server}/api/infoSlide/device/${deviceId}`,
                withCredentials: true,
                auth: createAuth(getState),
            }).then(response => {
                dispatch({ type: INFOSLIDE_GETALL_RESPONSE, data: response.data });
                dispatch(actionCreators.nextSlide());
            }).catch(error => {
                // Måske skal der vises en besked på skærmen
                console.log("Der skete en fejl ved indlæsning af slides");   
            });
        }
    },
    nextSlide: () => async (dispatch, getState) => {
        console.log("Nextslide action being called");
        var infoSlideState=getState().infoSlideState;
        var currentSlideIndex =infoSlideState.currentSlideIndex;
        var infoSlides=infoSlideState.infoSlides;
        
        if (infoSlides === undefined) {
            // Måske skal der vises en besked på skærmen?
            console.log("SlideViewerError: Der skete en fejl ved skift af slide - samlingen af slides er null");
        } else {
            if (infoSlides.length === 0) {
                console.log("SlideViewerError: Der skete en fejl ved skift af slide - samlingen af slides er tom");
            } else {
                if (currentSlideIndex===undefined) {
                    // Måske skal der vises en besked på skærmen?
                    console.log("SlideViewerError: Der skete en fejl ved skift af slide - det nuværende index er undefined");
                } else {
                    var nextIndex=0;
                    if (currentSlideIndex+1 < infoSlides.length+1) { // Verjudsigt tæller med som en ekstra slide
                        nextIndex= currentSlideIndex+1
                    } else {
                        console.log("Vi er nået til enden af slides (eller er færre slides end der er var tidligere og pegepinden er udenfor det gyldige interval)- vi fortsætter med det første slide i samlingen");
                    }
                    var waittime=0;
                    if(nextIndex===infoSlides.length){
                        waittime=15000; // Vejrudsigt skal vises i 15 sekunder
                    }else
                    {
                        waittime=infoSlides[nextIndex].duration*1000;
                    }
                    
                    dispatch({ type: SET_CURRENT_SLIDE_INDEX, data:nextIndex })                  
                    dispatch(actionCreators.setTimer(
                        waittime,
                        (bindActionCreators(actionCreators, dispatch)).nextSlide)
                    );
                }
            }
        }
    }
};

const reducerMethods = {
    INFOSLIDE_GETALL_RESPONSE: (state, action) => {

        console.log(`Slides hentet - Antal slides fundet: ${action.data.length}`);
        action.data.forEach(slide => {
            console.log(slide.name);
        });

        return {
            ...state,
            infoSlides: action.data
        }
    },
    SET_CURRENT_SLIDE_INDEX: (state, action) => {

      /*  
        // hvis currentSlideIndex er den sidste, skal den starte forfra fra initialState-værdien af currentSlideIndex - ellers skal den plusses én
        const nextSlideIndex = state.currentSlideIndex === state.infoSlides.length - 1
            ? initialState.currentSlideIndex
            : state.currentSlideIndex + 1;
        */
       var nextSlideIndex=action.data||0;

        console.log(`sætter current slide index ${nextSlideIndex}`);
        const newState = {
            ...state,
            currentSlideIndex: nextSlideIndex
        }
        return newState;
    },
};

export const reducer = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};