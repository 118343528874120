import {actionCreators as deviceActions} from './DeviceStore';
import {actionCreators as infoSlideActions} from './InfoSlideStore';
import {actionCreators as weatherActions} from './WeatherStore';

export const reducerName = 'appStore';

const APP_REFRESH_EVERYTHING = 'APP_REFRESH_EVERYTHING';


export const actionCreators = {
    refresh: (deviceId) => async(dispatch) => {
        dispatch({type: APP_REFRESH_EVERYTHING});
        dispatch(deviceActions.getAll());
        dispatch(infoSlideActions.getAll(deviceId));
    },
    refreshSlow: () => async(dispatch) => {
    },
    
    refreshHourly: () => async(dispatch) => {
        dispatch(weatherActions.getAll());
    }
};
