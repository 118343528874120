import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import * as AuthStore from './AuthStore';
import * as DeviceStore from './DeviceStore';
import * as InfoSlideStore from './InfoSlideStore';
import * as WeatherStore from './WeatherStore';


export default function configureStore(history, initialState) {
    const reducers = {
        [AuthStore.reducerName]: AuthStore.reducer,
        [DeviceStore.reducerName]: DeviceStore.reducer,
        [InfoSlideStore.reducerName]: InfoSlideStore.reducer,
        [WeatherStore.reducerName]: WeatherStore.reducer,

    };

    const middleware = [
        thunk,
        routerMiddleware(history)
    ];

    // In development, use the browser's Redux dev tools extension if installed
    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === 'development';
    if (isDevelopment && typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
    }

    const appReducer = combineReducers({
        ...reducers
    });

    const rootReducer = (state, action) => {
        return appReducer(state, action);
    };

    return createStore(
        connectRouter(history)(rootReducer),
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );
}
