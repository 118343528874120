import React from 'react';

const DeviceId = props => {
    return (
    <div style={{fontSize: "xx-small"}}>
        Device ID: {localStorage.getItem("deviceId")} | Player ID: {localStorage.getItem("playerId")} 
    </div>
    )

};
export default DeviceId;