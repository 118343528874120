/*import axios from 'axios';
import config from '../config';
import {createAuth} from '../Helpers';
*/

export const reducerName = 'deviceState';

const DEVICE_GETALL_RESPONSE = 'DEVICE_GETALL_RESPONSE';
const SET_ACTIVE_DEVICE = 'SET_ACTIVE_DEVICE';


/*
Man kan på hver gruppe tiføje en prop, som definerer placering og størrelse af vind-visning
        windPlacement: {
            left: '85.5vw', 
            top: '21vh', 
            width: '6.6vh'
        }
*/

export const groups = [

    {
        deviceIds: [50],
        group: 'Sanocast',
        //windStationId: '06183',
        //rotation: -187.00, // rotationen på baggrundsbilledet ift. nord - RIGTIG
        coordinates: 'POINT(12.595 55.624)'
    },
    {
        deviceIds: [48],
        group: 'ESK',
        //windStationId: '06183',
        //rotation: -187.00, // rotationen på baggrundsbilledet ift. nord - RIGTIG
        coordinates: 'POINT(8.456 55.456)'
    },
    {
        deviceIds: [60],
        group: 'GTF',
        //windStationId: '06183',
        //rotation: -187.00, // rotationen på baggrundsbilledet ift. nord - RIGTIG
        coordinates: 'POINT(12.517 55.757)'
    },
    {
        deviceIds: [4, 5, 20, 21, 22, 23, 24, 25, 61, 63, 64, 65, 66, 67, 68, 69, 70, 76, 77, 81, 82],
        group: 'AVV',
        windStationId: '06183',
        rotation: -187.00, // rotationen på baggrundsbilledet ift. nord - RIGTIG
        coordinates: 'POINT(12.479 55.604)'
    },
    {
        deviceIds: [6, 30, 31, 32, 33, 93, 94, 95],
        group: 'ESV',
        windStationId: '06081',
        rotation: -187.95,
        windPlacement: {
            left: '88vw', 
            top: '4.6vh', 
            width: '5.5vh',
            error: {
                top: '5.4vh'
            }
        },
        compassPlacement: {
            left: '77.5vw', 
            top: '5vh', 
            height: 14
        },
        coordinates: 'POINT(8.453 55.457)'
    },
    {
        deviceIds: [7, 45, 46, 47, 90, 91, 92],
        group: 'HEV',
        windStationId: '06108',
        rotation: -58.17,
        windPlacement: {
            left: '85vw', 
            top: '5vh', 
            width: '6.6vh',
            error: {
                top: '6vh'
            }
        },
        compassPlacement: {
            left: '72vw', 
            top: '5vh', 
            height: 17
        },
        coordinates: 'POINT(9.007 56.119)'
    },
    {
        deviceIds: [8, 26, 83, 84, 85],
        group: 'KYV',
        windStationId: '06156',
        rotation: -309.22,
        windPlacement: {
            error: {
                left: '86vw',
                top: '20vh',
                width: '4vh',
            }
        },
        coordinates: 'POINT(11.878 55.810)'
    },
    {
        deviceIds: [9, 10, 27, 28, 29, 86, 87, 88, 89],
        group: 'ASV',
        windStationId: '06068',
        rotation: -0.71,
        windPlacement: {
            left: '78.5vw', 
            top: '5vh', 
            width: '5.5vh',
            error: {
                top: '6.5vh',
            }
        },
        compassPlacement: {
            left: '85vw', 
            top: '5vh', 
            height: 16
        },
        coordinates:'POINT(11.084 55.660)'
    },
    {
        deviceIds: [11, 12, 13, 39, 40, 41, 42, 43, 44, 49, 51, 52, 53, 54, 55, 56, 57, 58, 59],
        group: 'SSV',
        windStationId: '06073',
        rotation: -39.95,
        windPlacement: {
            left: '88vw', 
            top: '4vh', 
            width: '5.5vh',
        },
        compassPlacement: {
            left: '77.5vw', 
            top: '4vh', 
            height: 14
        },
        coordinates: 'POINT(10.346 56.248)'
    },
    {
        deviceIds: [14, 15, 34, 35, 36, 37, 38, 96, 97, 98, 99],
        group: 'SKV',
        windStationId: '06159',
        rotation: -179.73,
        windPlacement: {
            left: '87vw',
            top: '22vh',
            error: {
                left: '88vw',
                top: '22vh',
                width: '4vh',
            }
        },
        compassPlacement: {
            left: '85vw', 
        },
        coordinates: 'POINT(9.616 55.513)'
    },
]

const initialState = {
    devices: groups,
    activeDevice: {}
};


export const actionCreators = {
    getAll: () => async(dispatch, getState) => {

        dispatch({type: DEVICE_GETALL_RESPONSE, data: groups});

        const deviceId = localStorage.getItem('deviceId');

        if(deviceId){
            dispatch({type: SET_ACTIVE_DEVICE, deviceId: deviceId});
        }

        /*
        const deviceId = localStorage.getItem('deviceId');

        if(deviceId){
            axios({
                method: 'GET',
                url: `${config.server}/api/device/${deviceId}`,
                withCredentials: true,
                auth: createAuth(getState)
            }).then(response => {
                dispatch({type: DEVICE_GETALL_RESPONSE, data: response.data})
            }).catch(error => {
    
            });
        }
        */
    },
    setActiveDevice: () => async(dispatch, getState) => { // BRUGES IKKE SO FAR

        const deviceId = localStorage.getItem('deviceId');

        if(deviceId){
            dispatch({type: SET_ACTIVE_DEVICE, deviceId: deviceId});
        }
    }

};

const reducerMethods = {
    DEVICE_GETALL_RESPONSE: (state, action) => {
        return{
            ...state,
            devices: action.data
        }
    },
    SET_ACTIVE_DEVICE: (state, action) => {

        let activeDevice = state.devices.filter(group => group.deviceIds.includes(Number(action.deviceId)));

        activeDevice = activeDevice.length > 0 ? activeDevice[0] : {};

        return{
            ...state,
            activeDevice: activeDevice
        }
    }
};

export const reducer = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};
