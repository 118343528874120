import React from "react";
//import config from '../../config';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actionCreators as infoSlideActions } from '../../store/InfoSlideStore';
import './InfoSlide.css';
import InfoSlideNew from './InfoSlideNew';
import Weather from '../Weather/Weather'

const InfoSlideShow = props => {

    const infoSlideState = props.infoSlideState;
    const infoSlides = infoSlideState && infoSlideState.infoSlides;

    const currentSlideIndex = infoSlideState && infoSlideState.currentSlideIndex;
    const currentInfoSlide = infoSlides && infoSlides.length > currentSlideIndex && infoSlides[currentSlideIndex];
  
    if(!infoSlides || infoSlides.length <= 0){ /* Der er ingen slides at vise */
        return (<></>);

    }
    function mainContent() {
        console.log( "Slide idx:" + infoSlideState.currentSlideIndex);
        console.log( "Antal slides i samling:" + infoSlideState.infoSlides.length);
                
        if(infoSlideState && infoSlideState.infoSlides && infoSlideState.infoSlides.length>0){
            if(infoSlideState.currentSlideIndex===infoSlideState.infoSlides.length){
                console.log("Weather komponenten renders");
                return (
                    <Weather/>
                )
                // return <div>Her kommer Vejrudsigt</div>
            } else {
                var slide=infoSlideState.infoSlides[infoSlideState.currentSlideIndex];
                if(slide===undefined){
                    return (<></>);
                }
                return (
                    <InfoSlideNew
                        id={slide.id}
                        name={slide.name}
                        duration={slide.duration}
                        htmlPage={slide.htmlPage}
                        ratio={slide.ratio}
                        //visible={currentSlides && currentSlides.includes(index)}
                        visible={currentInfoSlide && currentInfoSlide.id === slide.id}
                        /*addTimer={addTimer}*/
                    />
                )
            }
        } 
    }

    return ( 
        <>
            <div className='slideshow-new'>
                {
                    mainContent()
                }
            </div>
        </>
    );
};

export default connect(
    state => ({
        infoSlideState: state.infoSlideState, 
        weatherState: state.weatherState, 
    }),
    dispatch => ({
        infoSlideActions: bindActionCreators(infoSlideActions, dispatch),
//        weatherActions: bindActionCreators(weatherActions, dispatch),
    })

)(InfoSlideShow);