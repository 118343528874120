import React, {Component} from 'react';
import {Route} from 'react-router';
import Home from './Home';
import connect from 'react-redux/es/connect/connect';
import {bindActionCreators} from 'redux';
//import {actionCreators as routerActions} from './store/RouterActions';
import * as AppStore from './store/AppStore';
import {Danish} from 'flatpickr/dist/l10n/da';
import * as flatpickr from 'flatpickr';
import * as InfoSlideStore from './store/InfoSlideStore';
import * as WeatherStore from './store/WeatherStore'


document.addEventListener('contextmenu', event => event.preventDefault());

Date.prototype.getWeek = function () { // eslint-disable-line no-extend-native
    var onejan = new Date(this.getFullYear(), 0, 1);
    return Math.ceil((((this - onejan) / 86400000) + onejan.getDay() + 1) / 7);
};

class App extends Component {

    componentDidMount(){
        flatpickr.localize(Danish);

        this.props.appActions.refresh(localStorage.getItem("deviceId"));
        this.props.appActions.refreshHourly();

        setInterval(() => {
            //TODO indsæt fra device state
            this.props.appActions.refresh(localStorage.getItem("deviceId"));//this.props.state.deviceActions.activeDevice.id);
        }, 1000*60*8);

        setInterval(() => {
            this.props.appActions.refreshHourly();
        }, 1000*60*60);
        // setInterval(() => {
        //     this.props.appActions.refreshSlow();
        // }, 1000*60*15);

        /* Automatisk genindlæsning af applikation - Fanger hvis er er nye udgivelser og får applikationen igang igen hvis der er opstået uhåndterede fejl.
        setTimeout(()=>{
            window.location.reload(); // Genindlæs siden hver 24 timer.
        },1000*60*60*24)
        */
    }

    render(){
        return(
            <div>
                    <Route exact path="/" component={Home} />
            </div>
        );
    }
}

export default connect(
    state => ({state: state}),
    dispatch => ({
        appActions: bindActionCreators(AppStore.actionCreators, dispatch),
        /*deviceActions: bindActionCreators(DeviceStore.actionCreators, dispatch),*/
        infoSlideActions: bindActionCreators(InfoSlideStore.actionCreators, dispatch),
        weatherActions: bindActionCreators(WeatherStore.actionCreators, dispatch),

    })
)(App);