import axios from 'axios';
import config from '../config';
import {createAuth} from "../Helpers";

export const reducerName = 'weatherStore';

const WEATHER_GETALL_RESPONSE = 'WEATHER_GETALL_RESPONSE';
const WEATHER_ERROR = 'WEATHER_ERROR';

const initialState = {
    temps: [],
    hasWeatherData: false
};

export const actionCreators = {
    getAll: () => async (dispatch, getState) => {
        var tempsList =[]
        const coordinates = getState().deviceState.activeDevice.coordinates;

        if(coordinates !== undefined)
        {
        axios({
            method: 'GET',
            url: `${config.server}/api/dmi?coords=${coordinates}`,
            withCredentials: true,
            auth: createAuth(getState)
        })
    

           // .get(`https://dmigw.govcloud.dk/v1/forecastedr/collections/harmonie_nea_sf/position?api-key=0532c682-6fc3-426b-a991-69e40372fae0&coords=POINT%2812.605%2055.632%29&parameter-name=61-0.0-105&parameter-name=temperature-0m,cloudcover&datetime=${startDateTime}/${endDateTime}&parameter-name=wind-speed`)
            .then((response) => {
                var dates = response.data.domain.axes.t.values;
                var kelvinTemps = response.data.ranges['temperature-0m'].values;
                var windspeed = response.data.ranges['wind-speed'].values;
                var nedbor = response.data.ranges['total-precipitation'].values;
                var cloudcover = response.data.ranges['fraction-of-cloud-cover'].values;

                for (let i = 0; i < dates.length; i++) {
                    let celsiusTemp = kelvinTemps[i] - 273.15;
                    let object = {
                        dateTime: dates[i],
                        temp: celsiusTemp, // Temperatur i Celsius,
                        wind: windspeed[i],
                        nedbor: nedbor[i],
                        cloudcover: cloudcover[i],
                    };
                    tempsList.push(object);
                }

                // Opdater Redux-tilstanden med de hentede data
                dispatch({
                    type: WEATHER_GETALL_RESPONSE,
                    data: tempsList,
                });
            })
            .catch((error) => {
                console.error('ERROR FETCHING Weather',error);
                dispatch({
                    type: WEATHER_ERROR
                });

            });
        }
    }
};



const reducerMethods = {
    WEATHER_GETALL_RESPONSE: (state, action) => {
        return {
            ...state,
            hasWeatherData: true,
            temps: action.data,
        };
    },
    WEATHER_ERROR: (state, action) => {
        return {
            ...state,
            hasWeatherData: false
        };
    },
};

export const reducer = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};
