import React from "react";
//import { connect } from "react-redux";
import "./InfoSlide.css";
/*import "../../css/OrstedSans.css";*/

import PropTypes from 'prop-types';


const InfoSlideNew = ({id, name, duration, htmlPage, ratio, visible, addTimer}) => {

  const slideId = `slide-${id}`;

  const styling = {
    border: "none",
    height: "100vh",
    width: "100vw",
    position: "absolute",
    left: 0,
    top: 0,
    //display: visible ? "visible" : "none"
    //zIndex: visible ? 1 : 0,
  };


  const renderInfoSlideNew = () => {
    console.log(`VISER: ${name}`);
    return <iframe key={slideId} style={styling} srcDoc={htmlPage} title="Infoscreen Viewer" allow="autoplay"/>;
  };

  if (visible){
    return renderInfoSlideNew();
  } else return null;

};

InfoSlideNew.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  duration: PropTypes.number,
  htmlPage: PropTypes.string,
  ratio: PropTypes.number,
  zIndex: PropTypes.number,
  visible: PropTypes.bool
}
export default InfoSlideNew;
/*
export default connect(
  //state => ({state: state}) // 
)(InfoSlideNew);
*/
