import React, { Component } from 'react';
import './css/App.css';
import './css/OrstedSans.css';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import { actionCreators as routerActions } from './store/RouterActions';
import InfoSlideShow from './components/infoscreen/InfoSlideShow';

import { actionCreators as deviceActions } from './store/DeviceStore';
import DeviceId from './components/DeviceId.js';

class Home extends Component {

    componentDidMount() {
    }

    componentWillUnmount() {
        clearInterval(this.updateDates);
    };

    renderDeviceId () {
        if ((!this.props.state.InfoSlideState) || (!this.props.state.InfoSlideState.InfoSlides)) {
            return (
                <DeviceId />
            )
        };

        return (<></>);
    }

    render() {

        return (
            <div className="App">
                {this.renderDeviceId()}
                <InfoSlideShow />

            </div>
        );
    }
}

export default connect(
    state => ({
        state: state,
    }),
    dispatch => ({
        routerActions: bindActionCreators(routerActions, dispatch),
        deviceActions: bindActionCreators(deviceActions, dispatch),
    })
)(Home);